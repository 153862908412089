export const observeIntersection = (args: {
  className: string;
  targetClassName: string;
  // callback: (element?: Element) => void;
  threshold?: number;
}): void => {
  let observer: IntersectionObserver;

  const { className, targetClassName, threshold = 0.4 } = args;

  if ('IntersectionObserver' in window && targetClassName) {
    observer = new IntersectionObserver(onChange, {
      threshold,
    });
    const targets = Array.from(document.querySelectorAll(`.${targetClassName}`));
    targets.forEach((target) => observer.observe(target));
  } else {
    const targets = Array.from(document.querySelectorAll(`.${targetClassName}`));
    targets.forEach((target) => target && target.classList.add(className));
  }

  function onChange(entries: IntersectionObserverEntry[]) {
    entries.forEach((entry: IntersectionObserverEntry) => {
      const { target, intersectionRatio } = entry;

      if (target) {
        if (intersectionRatio >= threshold) {
          target.classList.add(className);
          observer.unobserve(target);
        } else {
          target.classList.remove(className);
        }
        // callback(target);
      }
    });
  }
};
